import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo, NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';


import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';


const MAX_MOBILE_SCREEN_WIDTH = 1024;

const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
  } = props;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      rootClassName={css.root}
      // className={className || css.root}
      sectionContentClassName={css.footer}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.container}>
        <LinkedLogo
          rootClassName={css.logoLink}
          logoClassName={css.logoWrapper}
          logoImageClassName={css.logoImage}
          linkToExternalSite={linkLogoToExternalSite}
          layout={logoLayout}
        />

        <div className={css.links}>
          <NamedLink name="NewListingPage" className={css.link}>
            <FormattedMessage id="Footer.sell" />
          </NamedLink>
          <NamedLink name="PrivacyPolicyPage" className={css.link}>
            <FormattedMessage id="Footer.about" />
          </NamedLink>
        </div>
        <div className={css.links}>
          <NamedLink name="TermsOfServicePage" className={css.link}>
            <FormattedMessage id="Footer.termsOfService" />
          </NamedLink>
          <NamedLink name="PrivacyPolicyPage" className={css.link}>
            <FormattedMessage id="Footer.privacyPolicy" />
          </NamedLink>
          <NamedLink name="CookiePolicyPage" className={css.link}>
            <FormattedMessage id="Footer.cookiePolicy" />
          </NamedLink>
          <NamedLink name="RefundPolicyPage" className={css.link}>
            <FormattedMessage id="Footer.refundPolicy" />
          </NamedLink>
          <NamedLink name="FaqPage" className={css.link}>
            <FormattedMessage id="Footer.faqs" />
          </NamedLink>
        </div>
        <div className={css.links}>
          <a className={css.link}>
            <FormattedMessage id="Footer.instagram" />
          </a>
          <a className={css.link}>
            <FormattedMessage id="Footer.twitter" />
          </a>
          <a className={css.link}>
            <FormattedMessage id="Footer.facebook" />
          </a>
          <a className={css.link}>
            <FormattedMessage id="Footer.linkedin" />
          </a>
        </div>
        {/* <div>
          <FormattedMessage id="Footer.established" />
        </div> */}
      </div>
      <p className={css.copyright}>
        <FormattedMessage id="Footer.copywright" values={{ lineBreak: < br /> }} />
      </p>

    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
